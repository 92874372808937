<template>
  <div class="copy-plugin">
    <a id="copy-btn" :data-clipboard-text="clipboardText" @click="copy">复制</a>
  </div>
</template>

<script>
  import Clipboard from 'clipboard';

  export default {
    data() {
      return {};
    },
    props: {
      clipboardText: {
        type: String,
        default: '',
      },
    },
    computed: {},
    mounted() {},
    methods: {
      copy() {
        const clipboard = new Clipboard('#copy-btn');
        clipboard.on('success', (e) => {
          e.clearSelection();
          this.$toast('复制成功');
        });
        clipboard.on('error', () => {
          this.$toast('复制失败');
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .copy-plugin {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 4%;
    height: 5vh;
    background-color: #fbefd9;
    a {
      color: #cc8b63;
      margin-left: 0.2rem;
      font-weight: 700;
      font-size: 13px;
    }
  }
</style>
